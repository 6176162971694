import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Home.css';
import logo from '../assets/logo.png'; // Adjust the path accordingly

function Home() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [combinedData, setCombinedData] = useState([]);
  const [winner, setWinner] = useState(null);
  const [winnerPhone, setWinnerPhone] = useState(''); // New state to hold the winner's phone number
  const [prize, setPrize] = useState(''); 
  const [countdown, setCountdown] = useState(null);
  const [showCongrats, setShowCongrats] = useState(false);

  const tokens = [
    '155|DMEO5WkX1xdPsGqZo1kQwJtVIxuacByAh4RB3l4z81f9e72b',
    '156|LsGDl7kFFxamLdKXKpJQRYYSnZCl0dSS9NKX0LE227ac12b1',
    '157|9ZmluffBkjjpngQWz4rpWQ32oM7z6N6S7lhwBhyO12fc4bd0',
    '158|MMAlfeXTXG0GcSLuhA8Uj556IlsEfsxveqWYwkKDdd31f0cd'
  ];

  useEffect(() => {
    const fireworks = document.querySelectorAll('.firework');
    fireworks.forEach(firework => {
      const randomDelay = (Math.random() * 2 + 1).toFixed(2);
      firework.style.setProperty('--delay', `${randomDelay}s`);
    });
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      handleFetchData();
    }
  }, [startDate, endDate]);

  const handleFetchData = async () => {
    setLoading(true);
    setWinner(null);
    setWinnerPhone(''); // Reset the winner's phone number
    setPrize('');
    setCountdown(null);
    setShowCongrats(false);

    const requests = tokens.map(token => {
      const url = `https://api.super-rate.x-tag.tech/api/tableServicesWithoutSub?startDate=${startDate}&endDate=${endDate}`;
      return axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
    });

    try {
      const responses = await Promise.all(requests);
      const data = responses
        .flatMap(response => response?.data?.data?.map(item => ({
          userName: item.userName,
          userPhone: item.userPhone, // Assuming userPhone is the correct key
        })))
        .filter((value, index, self) => self.findIndex(v => v.userName === value.userName) === index);

      setCombinedData(data);
      console.log("Combined Data:", data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowWinner = (prizeName) => {
    if (combinedData.length === 0) {
      alert("No data available. Please select a valid date range.");
      return;
    }

    setPrize(prizeName);
    setCountdown(3);

    const countdownInterval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown === 1) {
          clearInterval(countdownInterval);
          const randomIndex = Math.floor(Math.random() * combinedData.length);
          const randomWinner = combinedData[randomIndex];
          setWinner(randomWinner.userName);
          setWinnerPhone(randomWinner.userPhone); // Set the winner's phone number
          setShowCongrats(true);
          return null;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    // Reset everything after 15 seconds
    setTimeout(() => {
      setWinner(null);
      setWinnerPhone(''); // Reset the winner's phone number
      setPrize('');
      setShowCongrats(false);
    }, 1500000);
  };

  return (
    <div className="container">
      {loading && <div className="loader"></div>}
      
      {/* Logo and text */}
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="main-heading">السحب على جوائز أرتيستا المميزة</h1>
      </div>
  
      {countdown !== null && (
        <div className="countdown-overlay">
          <h2 className="countdown-number">{countdown}</h2>
        </div>
      )}
  
      {showCongrats && winner && prize && (
        <div className="winner-container">
          <h2 className="winner-name">{winner}</h2>
        
          <h2 className="winner-text">مبارك الفوز بـ {prize}</h2>
          <h2 className="winner-text">تستاهل كل خير</h2>
          <h2 className="winner-text">❤️❤️</h2>
          <h3 className="winner-phone">رقم الفائز: {winnerPhone}</h3> {/* Displaying the winner's phone number */}
          <div className="fireworks-container">
            <div className="firework" style={{ marginLeft: "10px" }}></div>
            <div className="firework2" style={{ marginLeft: "20px" }}></div>
            <div className="firework3" style={{ marginLeft: "30px" }}></div>
            <div className="firework4" style={{ marginLeft: "40px" }}></div>
            <div className="firework5" style={{ marginLeft: "50px" }}></div>
          </div>
        </div>
      )}
      
      {!loading && (
        <div className="paper">
          <div className="content">
            <h1>تحديد المدة</h1>
            <div className="date-picker-container">
              <input
                type="date"
                className="date-picker"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
              />
              <input
                type="date"
                className="date-picker"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="End Date"
              />
            </div>
            <h1>اختر الجائزة لتحديد الفائز بها</h1>
            {combinedData.length > 0 && !winner && (
              <div className="button-grid">
                <div className="button-row">
                  <button className="show-winner-button button-91" onClick={() => handleShowWinner('جلسة بتييض أسنان')}>
                  جلسة تبييض أسنان
                  </button>
                  <button className="show-winner-button button-91" onClick={() => handleShowWinner('جلسة تنظيف أسنان')}>
                    جلسة تنظيف أسنان
                  </button>
                </div>
                <div className="button-row">
                  <button className="show-winner-button button-91" onClick={() => handleShowWinner('زرعات أسنان')}>
                    زرعات أسنان
                  </button>
                  <button className="show-winner-button button-91" onClick={() => handleShowWinner('باقه جلسات نضاره البشرة')}>
                    باقة جلسات نضارة البشرة
                  </button>
                </div>
                <div className="button-row centered">
                  <button className="show-winner-button button-91" onClick={() => handleShowWinner('ابتسامة هوليوود')}>
                    ابتسامة هوليوود
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
